@import "variables";

.not-found {
  .image-wrapper {
    padding-top: 100px;
    background-color: #e4f8ff;

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .text-wrapper {
    padding: 50px 100px;
    background-color:  #71d8f7;
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        color: #fff;
      }
    }
  }

  @media (max-width: $small-size) {
    .text-wrapper {
      padding: 30px 20px;
      h3 {
        font-size: $fs-l;
      }
    }
  }
}