.not-found .image-wrapper {
  padding-top: 100px;
  background-color: #e4f8ff;
}

.not-found .image-wrapper img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.not-found .text-wrapper {
  padding: 50px 100px;
  background-color: #71d8f7;
  color: #fff;
}

.not-found .text-wrapper a {
  color: #fff;
  text-decoration: underline;
}

.not-found .text-wrapper a:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .not-found .text-wrapper {
    padding: 30px 20px;
  }

  .not-found .text-wrapper h3 {
    font-size: 18px;
  }
}

